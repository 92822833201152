import ShowcaseSection from '@/components/blocks/Showcase'
import { ImageKit } from '@/components/global/ImageKit'
import { motion } from 'framer-motion'

const blenderShowcaseHeader = {
    title: 'Built for Blender',
    subtitle:
        'We built Renderjuice to support Blender as thoroughly as possible.',
    description: `F12 and render with the latest Blender features, customizations, addons, and more as if it were all running on your machine, just way, way faster.`,
}

const blenderSections = [
    {
        title: 'Composite Nodes',
        description:
            "Render separate different passes or combine them with composite nodes just like you'd expect",
        imageSrc: '/images/assets/square.svg',
        imageAlt: '',
    },
    {
        title: 'Vast File Format Support including EXR Multilayer',
        description:
            'Render outputs to EXR, TIFF, PNG, JPG, and a slew of other formats',
        imageSrc: '/images/assets/square.svg',
        imageAlt: '',
    },
]

const blenderLearnMoreProps = {
    href: '/',
    text: 'More on how we support Blender',
}

export default function BlenderShowcaseSection() {
    return (
        <section
            id='blender'
            className='mb-4 px-4 text-center md:max-w-5xl md:px-12'
        >
            <ShowcaseSection
                variant='blender'
                ctaText='Get Started'
                headerProps={blenderShowcaseHeader}
                cardProps={blenderSections}
                learnMoreProps={blenderLearnMoreProps}
                cards={[
                    <BlenderCardOne key='blender-card-one' />,
                    <BlenderCardTwo key='blender-card-two' />,
                ]}
            />
        </section>
    )
}

const BlenderCardOne = () => {
    return (
        <div className='opacity-90 fadeout-vertical grid justify-center gap-4 px-8 py-24 max-h-[400px]'>
            <ImageKit
                src={'/composite-nodes.png'}
                loading='lazy'
                alt='Blender Composite Nodes'
                className='w-full h-auto'
                transformation={[{ width: 794 }, { height: 380 }]}
                width={794}
                height={380}
            />
        </div>
    )
}

const BlenderCardTwo = () => {
    return <SkeletonTwo />
}

export const SkeletonTwo = () => {
    const images = [
        {
            url: '/unsplash/mountains.png',
            format: 'EXR',
            alt: '',
            rotation: 3,
        },
        {
            url: '/unsplash/waterfall.png',
            format: 'PNG',
            alt: '',
            rotation: 5,
        },
        {
            url: '/unsplash/beach.png',
            format: 'TIFF',
            alt: '',
            rotation: 2,
        },
        {
            url: '/unsplash/forest.png',
            format: 'HDR',
            alt: '',
            rotation: 8,
        },
        {
            url: '/unsplash/trees.png',
            format: 'JPEG',
            alt: '',
            rotation: 1,
        },
    ]

    const imageVariants = {
        whileHover: {
            scale: 1.05,
            rotate: 0,
            zIndex: 100,
        },
        whileTap: {
            scale: 1.05,
            rotate: 0,
            zIndex: 100,
        },
    }

    const containerVariants = {
        animate: {
            x: [0, -100],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: 'loop',
                    duration: 20,
                    ease: 'linear',
                },
            },
        },
    }

    return (
        <div className='relative flex flex-col items-start p-8 gap-4 h-full overflow-hidden'>
            <motion.div
                className='flex flex-row -ml-20'
                variants={containerVariants}
                animate='animate'
            >
                {images
                    .concat(images)
                    .map(({ url, format, alt, rotation }, idx) => (
                        <motion.div
                            variants={imageVariants}
                            key={`images-first-${idx}`}
                            style={{
                                rotate: rotation,
                            }}
                            whileHover='whileHover'
                            whileTap='whileTap'
                            className='rounded-xl -mr-4 mt-4 p-1 bg-white dark:bg-neutral-800 dark:border-neutral-700 border flex-shrink-0 overflow-hidden'
                        >
                            <ImageKit
                                transformation={[
                                    {
                                        height: '50px',
                                    },
                                ]}
                                loading='lazy'
                                src={url}
                                alt='bali images'
                                className='rounded-lg h-10 w-10 md:h-24 md:w-24 object-cover flex-shrink-0'
                            />
                            <h6>{format}</h6>
                        </motion.div>
                    ))}
            </motion.div>
            <motion.div
                className='flex flex-row'
                variants={containerVariants}
                animate='animate'
            >
                {images
                    .concat(images)
                    .map(({ url, format, alt, rotation }, idx) => (
                        <motion.div
                            key={`images-second-${idx}`}
                            style={{
                                rotate: rotation,
                            }}
                            variants={imageVariants}
                            whileHover='whileHover'
                            whileTap='whileTap'
                            className='rounded-xl -mr-4 mt-4 p-1 bg-white dark:bg-neutral-800 dark:border-neutral-700 border border-neutral-100 flex-shrink-0 overflow-hidden'
                        >
                            <ImageKit
                                loading='lazy'
                                width={50}
                                height={50}
                                src={url}
                                alt={`File Format ${format} Sample Image`}
                            />
                            <h6>{format}</h6>
                        </motion.div>
                    ))}
            </motion.div>
        </div>
    )
}
